import MiddleBlockAccount from "./MiddleBlockAccount"
import MiddleBlockAcquirer from "./MiddleBlockAcquirer"
import MiddleBlockCard from "./MiddleBlockCard"
import MiddleBlockNotInformation from "./MiddleBlockNotInformation"
import MiddleBlockLoan from "./MiddleBlockLoan"

export const MiddleBlock = ({ category, content }) => {
  switch (category) {
    case "loans":
      return <MiddleBlockLoan content={content} />
    case "accounts":
      return <MiddleBlockAccount content={content} />
    case "acquirer":
      return <MiddleBlockAcquirer content={content} />
    case "card":
      return <MiddleBlockCard content={content} />
    default:
      return <MiddleBlockNotInformation />
  }
}
