import * as S from "../../styles.js"

const MiddleBlockNotInformation = () => {
  return (
    <div css={S.MiddleBlockNotInformation}>
      {/* Nenhum produto encontrado */}
    </div>
  )
}

export default MiddleBlockNotInformation
