import useStoryblok from "@mkt/utils/storyblok/useStoryblok"

const useLanding = ({ pageContext }) => {
  const data = useStoryblok(pageContext.content)
  const content = data?.content || pageContext.content

  const {
    hero,
    lists_highlighted: listsHighlighted,
  } = content


  return {
    content,
    hero,
    listsHighlighted,
  }
}

export default useLanding
