import Icon from "@mkt/ui/components/common/Icon"

export default function Padlock(props) {
  return (
    <Icon
      iconMarkup={
        '<svg width="15" height="21" xmlns="http://www.w3.org/2000/svg"><g stroke="#444444" fill="none" fill-rule="evenodd"><path d="M1 17.889v.844c0 .466.388.845.867.845h11.266a.856.856 0 0 0 .867-.845V9.444a.856.856 0 0 0-.867-.844H1.867A.856.856 0 0 0 1 9.444v8.445Z" stroke-linejoin="round"/><path d="M3.167 8.6V5.222C3.167 2.89 5.107 1 7.5 1c2.393 0 4.333 1.89 4.333 4.222V8.6" stroke-linejoin="round"/><path d="M7.5 12.4v3.378"/></g></svg>'
      }
      iconName="Padlock"
      {...props}
    />
  )
}
