import { useEffect } from "react"

import useAnalytics from "@mkt/ui/hooks/useAnalytics"

import { navigate } from "gatsby"

// To Do: duplicated file apps/ccbr/src/pages/Card/components/CardsFeatured/hook

const useProductsHighlights = ({ highlights }) => {
	const { product, productsList, list } = useAnalytics()
	const currentListId =  "cartoes-em-destaque"
	const currentListName =  "Cartões em destaque"

	const handleProductClick = (card, position) => {
    const { content, full_slug, slug } = card
    const redirectUrl = content?.redirect_url
  
    const isProductRedirect = redirectUrl && redirectUrl.startsWith("https://www.iq.com.br/produto")
    const productSlug = isProductRedirect ? redirectUrl : full_slug?.replace("products", "") || slug
  
    product.setProduct({ list, position, ...card })
  
    navigate(productSlug)
	}

	useEffect(() => {
		list.setList({data: { id: currentListId, name: currentListName}})
	}, [])

	useEffect(() => {
		if (!productsList.data) {
			productsList.setProductsList(highlights)
		}
	}, [ productsList.data ])

	return {
		handleProductClick
	}
}

export default useProductsHighlights