import Text from "@mkt/ui/components/Azulis/Text"
import Highlight from "./components/Highlight"
import * as S from "./styles"

const ProductsHighlight = ({ content, title }) => {
  return (
    <div css={S.ProductsHighlight} data-scroll="productListViewed">
      <section css={S.ProductsHighlightWrapper}>
        <Text as="h2" variant={["HeadingThree", S.ProductHighlightTitle]}>
          {title}
        </Text>
        <ul css={S.ProductsHighlightList}>
          <Highlight pageContext={content} />
        </ul>
      </section>
    </div>
  )
}

export default ProductsHighlight
