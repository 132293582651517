import * as S from "../../styles.js"

const MiddleBlockAccount = (content) => {
  const highlight = content?.content?.details
  return (
    <ul css={S.MiddleBlockText} className="middle-block-account">
      {highlight?.map((item, index) => (
        <li
          css={S.MiddleBlockTextItem}
          className={`item-${index}`}
          key={`#item-middle-block-account-${index}`}
        >
          {item?.title}:{" "}
          <strong>{item?.desc?.content[0]?.content[0]?.text}</strong>
        </li>
      ))}
    </ul>
  )
}

export default MiddleBlockAccount
