import * as S from "../../styles.js"

const MiddleBlockAcquirer = (content) => {
  const highlight = content?.content
  return (
    <>
      <div css={S.MiddleBlockText} className="middle-block-acquirer">
        <p>Com chip e com bobina</p>
      </div>
      <div css={S.MiddleBlockPrice}>
        {highlight?.price_old && (
          <s className="old-price">De 12x de {highlight?.price_old}</s>
        )}
        <p className="current-price">
          por apenas 12X de <span>R${highlight?.price}</span>
        </p>
      </div>
    </>
  )
}

export default MiddleBlockAcquirer
