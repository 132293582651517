import { render } from "storyblok-rich-text-react-renderer"

import * as S from "../../styles.js"

const MiddleBlockLoan = (content) => {
  const benefits = content?.content?.loans_info
  const loanBenefits = benefits.filter((benefit) => {
    const benefitList = ["Valor", "Aceita negativado", "Parcelamento"]
    return benefitList.includes(benefit?.title)
  })
  return (
    <div>
      <ul css={S.MiddleBlockText} className="middle-block-loan">
        {loanBenefits.map((benefit, index) => (
          <li
            css={S.MiddleBlockTextItem}
            key={`#middle-block-loan-information-${index}`}
          >
            {benefit?.title}: {render(benefit?.desc)}
          </li>
        ))}
      </ul>
      <div css={S.MiddleBlockFee}>
        <span>{content?.content?.minimum_interest}</span>
        <p>
          Taxa Mínima <strong>Ao Mês</strong>
        </p>
      </div>
    </div>
  )
}

export default MiddleBlockLoan
