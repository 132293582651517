import {
  MachineComplete,
  MachineWithChip,
  MachineWithoutChip,
  OutlineMeiBill,
  OutlineMoneyBag,
  OutlineMoneyBill,
  OutlineMoneyPouch,
  OutlineMoneyStash,
  OutlineNoAnnualFee,
  OutlineSupermarketCart,
  OutlineUserDenied,
  OutlineWallet,
} from "@mkt/ui/icons/Azulis"

const useBenefit = ({ location }) => {
  const { pathname } = location

  const buildSlugLink = (title) => {
    let titleLowerCase = title.toLowerCase()
    let titleNormalized = titleLowerCase
      .normalize("NFD")
      .replace(/([\u0300-\u036f])/g, "")
    return titleNormalized.replace(/[^\w\-]+/g, "-")
  }

  const handleCategoryLink = () => {
    const categoryFilter = category || buildSlugLink(title)
    return `${pathname}listagem/?category=${categoryFilter}`
  }

  const ComponentIcons = {
    wallet: OutlineWallet,
    "money-pouch": OutlineMoneyPouch,
    "money-bill": OutlineMoneyBill,
    "machine-with-chip": MachineWithChip,
    "machine-without-chip": MachineWithoutChip,
    "machine-complete": MachineComplete,
    "supermarket-cart": OutlineSupermarketCart,
    "no-annual-fee": OutlineNoAnnualFee,
    "user-denied": OutlineUserDenied,
    "mei-bill": OutlineMeiBill,
    "money-bag": OutlineMoneyBag,
    "money-stash": OutlineMoneyStash,
  }

  return { handleCategoryLink, ComponentIcons }
}

export default useBenefit
