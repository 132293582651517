import { render } from "storyblok-rich-text-react-renderer"
import Text from "@mkt/ui/components/Azulis/Text"
import Img from "@mkt/ui/components/common/Img"

import * as S from "./styles"

const Hero = ({ content }) => {
  const {
    title,
    image_desktop: imageDesktop,
    image_mobile: imageMobile,
  } = content[0]

  return (
    <div css={S.Hero}>
      <section css={S.HeroWrapper}>
        <Text variant={["HeadingTwo", "TextWhite", "isContainer"]} as="h2">
          <span css={S.HeroTitle}>{render(title)}</span>
        </Text>
        <figure css={[S.HeroImage]}>
          <picture>
            <source media="(min-width:767px)" srcSet={imageDesktop.filename} />
            <Img src={imageMobile.filename} alt="Imagem de cartões" />
          </picture>
        </figure>
      </section>
    </div>
  )
}

export default Hero
