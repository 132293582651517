import { css } from "@emotion/react"
import { colors } from "@mkt/azulis/src/styles/Tokens"
import { above, below } from "@mkt/ui/styles/Breakpoints"
import { container } from "@mkt/ui/styles/Functions"

export const Hero = css`
  background-color: ${colors.blue.default};
  overflow: hidden;
  height: 408px;
  padding-top: 54px;

  ${above["m"]} {
    height: 298px;
    padding-top: 0;
  }
`

export const HeroWrapper = css`
  height: 100%;
  position: relative;
  ${container};

  ${above["m"]} {
    display: flex;
    align-items: center;
  }
`

export const HeroTitle = css`
  max-width: 310px;
  position: relative;
  display: block;
  margin-bottom: 36px;

  ${above["l"]} {
    position: absolute;
    margin-bottom: 0;
  }

  .color-green {
    color: ${colors.green.default};
  }
`

const HeroImageContent = css`
  font-size: 0;
  line-height: 0;

  img {
    transition: filter 0.5s;
  }
`

export const HeroImage = css`
  ${HeroImageContent};
  position: absolute;
  bottom: 0;

  ${below["m"]} {
    text-align: center;
  }

  ${above["m"]} {
    width: 50%;
    right: 10px;

    * {
      width: 100%;
    }
  }

  ${above["l"]} {
    width: 55%;
    right: 100px;
  }

  ${above["ll"]} {
    width: auto;
  }
`
