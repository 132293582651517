import Text from "@mkt/ui/components/Azulis/Text"
import Condition from "@mkt/ui/components/common/Condition"
import { CheckGreen } from "@mkt/ui/icons/Azulis"

import * as S from "../../styles.js"

const MiddleBlockCard = (content) => {
  const { minimum_income, annuity, description } = content?.content
  return (
    <>
      <div css={S.MiddleBlockText} className="middle-block-card">
        <p>Renda mínima: {minimum_income}</p>
        <p>Anuidade: {annuity}</p>
      </div>
      <Condition condition={!!description}>
        <div css={S.MiddleBlockCardDesc}>
          <Text as="span" variant={["Text14"]}>
            <CheckGreen css={S.CheckGreenImage} />
            {description}
          </Text>
        </div>
      </Condition>
    </>
  )
}

export default MiddleBlockCard
