import { Link } from "gatsby"
import { render } from "storyblok-rich-text-react-renderer"
import Text from "@mkt/ui/components/Azulis/Text"
import SbEditable from "@mkt/ui/components/common/SbEditable"

import useBenefit from "./hooks"
import * as S from "./styles"

const Benefits = ({ content, location }) => {
  const [firstContent] = content
  const { boxes } = firstContent
  const { handleCategoryLink, ComponentIcons } = useBenefit({ location })

  return (
    <SbEditable content={content} css={S.Benefits}>
      <div css={S.BenefitsContainer}>
        {boxes?.map((box, index) => {
          const {
            category,
            title,
            icon,
            button_text: buttonText,
            button_link,
            benefits,
            obs: acquirerPublic,
          } = box

          const IconBenefit = ComponentIcons[icon]
          const redirectLink =
            button_link || handleCategoryLink({ title, category })

          return (
            <SbEditable content={box} css={S.BenefitBox}>
              <div css={S.BenefitBoxIcon}>
                <IconBenefit />
              </div>
              <Text variant={[S.BenefitBoxTitle, "HeadingThree"]} as="h3">
                {title}
              </Text>
              <div css={S.BenefitBoxList}>{render(benefits)}</div>

              <Link to={redirectLink} css={S.Link}>
                {buttonText}
              </Link>

              <ul>
                {acquirerPublic?.content?.map((item, index) => {
                  if (!item.content) {
                    return null
                  }
                  const [firstItemAcquirer] = item?.content
                  const itemAcquirer = firstItemAcquirer.text
                  return (
                    <li
                      css={S.BenefitBoxPublicItem}
                      className={`acquirer-item-${index}`}
                      key={`acquirer-item-${index}`}
                    >
                      {render(itemAcquirer)}
                    </li>
                  )
                })}
              </ul>
            </SbEditable>
          )
        })}
      </div>
    </SbEditable>
  )
}

export default Benefits
