import Button from "@mkt/ui/components/Azulis/Button"
import Text from "@mkt/ui/components/Azulis/Text"
import Img from "@mkt/ui/components/common/Img"
import Condition from "@mkt/ui/components/common/Condition"
import Padlock from "@mkt/ui/icons/Azulis/Padlock"

import * as S from "../styles"
import { MiddleBlock } from "./MiddleBlock"
import useProductsHighlights from "../hook"

const Highlight = ({ pageContext }) => {
  const { handleProductClick } = useProductsHighlights({ highlights: pageContext })

  return (
    <>
      {pageContext?.map((highlight, index) => {
        const parsedContent = highlight && highlight.node && JSON.parse(highlight.node.content)
        const content = parsedContent
        const { name: product } = highlight
        const category = content?.component?.replace(
          "marketplace_product_",
          ""
        )

        return (
          <div
            data-position={index}
            data-scroll="productViewed"
            css={S.ProductHighlightItem}
            className={`highlight-${index}`}
            key={`highlight-section-azulis-${index}`}
          >
            <div>
              <figure css={S.ProductHighlightImageContent}>
                <Img
                  src={content?.image?.filename}
                  alt={product}
                  css={S.ProductHighlightImageCard}
                />
              </figure>
              <Text as="p" variant={[S.ProductHighlightTitleTop]}>
                {product}
              </Text>

              <MiddleBlock content={content} category={category} />
            </div>
            <div>
              <Button
                color="inverted"
                justify="center"
                onClick={() => handleProductClick(highlight, index)}
              >
                <Condition
                  condition={category === "accounts"}
                  elseCondition="Peça já"
                >
                  Abra sua conta
                </Condition>
              </Button>
              <div css={S.LinkSecureContent}>
                <Padlock />
                <Text as="p" variant={["TextSubTitleSecondaryDark", "Text12"]}>
                  Link seguro
                </Text>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default Highlight
